<template>
  <!-- Login -->
  <div class="auth-wrapper auth-v1 px-1">
    <span class="bg" />
    <div class="auth-inner py-2">
      <b-card id="page-login" border-variant="transparent" bg-variant="transparent" class="mb-0">
        <b-link class="d-block">
          <div class="d-flex justify-content-center align-items-center mb-1">
            <b-img-lazy width="150" class="mb-1" fluid :src="require('@/assets/images/logo/kia-white.png')" />
          </div>
        </b-link>
        <validation-observer ref="loginForm">
          <b-form class="mt-2" @submit.prevent="recaptcha">
            <!-- email -->
            <b-form-group>
              <label class="text-white" for="email">
                {{ $t("message.user") }}
              </label>
              <validation-provider #default="{ errors }" name="Correo electrónico " rules="required|email" mode="passive">
                <b-form-input id="email" v-model="userEmail" class="formInput text-white" name="login-email"
                  :state="errors.length > 0 ? false : null" :placeholder="$t('message.email_example')" autofocus />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label class="text-white" for="password">
                  {{ $t("message.password") }}
                </label>
              </div>
              <validation-provider #default="{ errors }" name="Contraseña" rules="required|min:8|password" vid="password"
                mode="passive">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="password" v-model="password" :type="passwordFieldType"
                    class="form-control-merge formInput text-white" :state="errors.length > 0 ? false : null"
                    name="login-password" :placeholder="$t('message.password')" onpaste="return false" />

                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- platform -->
            <b-form-group v-if="!params">
              <label class="text-white" for="platform">
                {{ $t("message.platform") }}
              </label>
              <validation-provider #default="{ errors }" name="Plataforma" rules="required" mode="passive">
                <!-- <b-form-select id="platform" v-model="selected" :options="options" class="formInput" name="login-platform"
                  :state="errors.length > 0 ? false : null"/> -->
                <v-select id="platform" v-model="selected" :options="options" :state="errors.length > 0 ? false : null"
                  class="text-white" :placeholder="$t('message.platform_select')" label="text" onpaste="return false" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <b-form-checkbox id="remember-me" v-model="remember" name="checkbox-1">
                  <label class="text-white">
                    {{ $t("message.remember") }}
                  </label>
                </b-form-checkbox>

                <b-link :to="{ name: 'recover_password' }">
                  <small class="text-white">
                    {{ $t("message.forgot") }}
                  </small>
                </b-link>
              </div>
            </b-form-group>
            <!-- Politicas de privacidad -->
            <div style="display: flex;">
              <b-form-checkbox v-model="tycosCheck" class="mr-0 mt-50" name="is-rtl" />
              <p @click="showTycos" style="padding-top: .5em; cursor: pointer;">Acepto la Política de Uso y Aviso de
                Privacidad</p>
            </div>
            <!-- Boton -->
            <div v-if="!$store.getters['app/isLoginLocked']" class="text-center mt-3">
              <b-button id="buttonLogin" v-if="loading == false" variant="light" :disabled="!tycosCheck"
                type="submit" class="font-weight-bolder px-3">
                <div>Login</div>
              </b-button>

              <b-spinner v-else />
            </div>

            <p class="text-center text-danger" v-else>
              Se han detectado más tres intentos de inicio de sesión, intente
              más tarde
            </p>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
    <!-- MODAL TYCOS -->
    <b-modal v-model="tycos" centered hide-footer no-close-on-backdrop>
      <div style="max-height: 30vw; overflow: scroll; text-align: justify;">
        <b-card-title class="mb-0 tycos_title_modal d-flex justify-content-center">
          POLÍTICAS DE USO DE CUENTAS DE USUARIO DEL SISTEMA KIA MARKETING PORTAL
        </b-card-title>

        <b-card-text style="margin-top: 1em; margin-right: 1rem;">
          <p>POLÍTICAS DE USO DE CUENTAS DE USUARIO DEL SISTEMA KIA MARKETING PORTAL<br /><br />
            1. OBJETIVO Y ALCANCE<br /><br />
            Esta Política establece los lineamientos, procesos, requisitos y responsabilidades que deben de acatar todas
            aquellas personas que cuenten con un Usuario y contraseña para accesar al Kia Marketing Portal propiedad de
            Kia México, S.A. de C.V. (KMX).<br /><br />
            El registro como Usuario dentro del Kia Marketing Portal implica la aceptación expresa de las siguientes
            Políticas.<br /><br />
            2. DEFINICIONES<br /><br />
            Administrador del Sistema Kia Marketing Portal: significa el Usuario Autorizado que tiene los permisos de las
            herramientas proporcionadas por la plataforma, para modificar, cargar y descargar materiales, así como el dar
            de alta Usuarios o modificarlos.<br /><br />
            Aviso de Privacidad: significa el documento a disposición del titular de los datos personales de forma
            electrónica generado por KMX con el objeto de informarle los propósitos del tratamiento y la finalidad de la
            obtención de los mismos a partir del momento en el que se recaben sus datos personales para el uso del Kia
            Marketing Portal.<br /><br />
            Contraseña: significa la clave de acceso del Usuario Autorizado que le dará acceso al Kia Marketing Portal, la
            cual es personal e intransferible.<br /><br />
            Coordinador Regional de Marketing: significa el empleado de KMX, en el área de Marketing Regional, que
            mantiene a su cargo diferentes zonas en que es dividido el territorio mexicano para su atención y que cuenta
            con un Usuario Autorizado.<br /><br />
            Cuenta de Usuario Autorizado: significa todas las cuentas que tienen acceso al Kia Marketing Portal, en la
            cual podrán visualizar, descargar y compartir archivos compartidos por KMX, así como la carga y descarga de
            reportes con evidencias fotográficas e información financiera y en general mensual de acuerdo a los
            Distribuidores a su cargo.<br /><br />
            Distribuidor: significa la persona moral autorizada para vender productos y prestar servicios de la marca Kia
            en territorio mexicano.<br /><br />
            1S Punto de Venta: significa el punto de venta autorizado como adicional a un Distribuidor para vender
            productos y prestar servicios de la marca Kia en territorio mexicano.<br /><br />
            • 1. Kia Aeropuerto<br />
            • 2. Kia Ahome<br />
            • 3. Kia Alaria<br />
            • 4. Kia Altas Montañas<br />
            • 5. Kia Angelópolis<br />
            • 6. Kia Ánimas<br />
            • 7. Kia Avenida<br />
            • 8. Kia Bahía<br />
            • 9. Kia Baja Sur<br />
            • 10. Kia Bajío<br />
            • 11. Kia Bernárdez<br />
            • 12. Kia Boca<br />
            • 13. Kia Bonampak<br />
            • 14. Kia Brisas<br />
            • 15. Kia Cabos<br />
            • 16. Kia Cajeme<br />
            • 17. Kia Campestre<br />
            • 18. Kia Capital<br />
            • 19. Kia Carretera 57<br />
            • 20. Kia Carretera Nacional<br />
            • 21. Kia Center<br />
            • 22. Kia Cholula<br />
            • 23. Kia Coacalco<br />
            • 24. Kia Coapa<br />
            • 25. Kia Coatza<br />
            • 26. Kia Coliman<br />
            • 27. Kia Corregidora<br />
            • 28. Kia Country<br />
            • 29. Kia Country Club<br />
            • 30. Kia Cuautitlán<br />
            • 31. Kia Cumbres<br />
            • 32. Kia del Duero<br />
            • 33. Kia Del Valle<br />
            • 34. Kia Diamante<br />
            • 35. Kia División<br />
            • 36. Kia Ecatepec<br />
            • 37. Kia Esmeralda<br />
            • 38. Kia Fresnillo (1S)<br />
            • 39. Kia Frontera<br />
            • 40. Kia Futura<br />
            • 41. Kia Galerías<br />
            • 42. Kia González Gallo<br />
            • 43. Kia Gonzalitos<br />
            • 44. Kia Guadiana<br />
            • 45. Kia Innova<br />
            • 46. Kia Interlomas<br />
            • 47. Kia Iztapalapa<br />
            • 48. Kia Juárez<br />
            • 49. Kia Juventud<br />
            • 50. KIA Laguna<br />
            • 51. KIA Laredo<br />
            • 52. Kia Lindavista<br />
            • 53. Kia Lomas<br />
            • 54. Kia Lomas Verdes<br />
            • 55. Kia López Mateos<br />
            • 56. Kia Los Fuertes<br />
            • 57. Kia Malinche<br />
            • 58. Kia Manantial<br />
            • 59. Kia Mariano Escobedo<br />
            • 60. Kia Max<br />
            • 61. Kia Metepec<br />
            • 62. Kia Mil Cumbres<br />
            • 63. Kia Morelos<br />
            • 64. Kia Morelos Sur (1S)<br />
            • 65. Kia Nayarita<br />
            • 66. Kia Norte<br />
            • 67. Kia Nova Qro<br />
            • 68. Kia Pacific<br />
            • 69. Kia Pape<br />
            • 70. Kia Paricutín<br />
            • 71. Kia Patria<br />
            • 72. Kia Pedregal<br />
            • 73. Kia Península<br />
            • 74. Kia Playacar<br />
            • 75. KIA Plaza del Valle<br />
            • 76. Kia Polanco (1S)<br />
            • 77. Kia Poliforum<br />
            • 78. Kia Primavera<br />
            • 79. Kia Puerto Escondido<br />
            • 80. Kia Ruiz Cortines<br />
            • 81. Kia Salina Cruz<br />
            • 82. Kia San Joaquín<br />
            • 83. Kia San Juan<br />
            • 84. Kia Santa Anita<br />
            • 85. Kia Santa Fe<br />
            • 86. Kia Satélite<br />
            • 87. Kia Sendero<br />
            • 88. Kia Serdán<br />
            • 89. Kia Sureste<br />
            • 90. Kia Tajín<br />
            • 91. Kia Texcoco<br />
            • 92. Kia Tláhuac<br />
            • 93. Kia Vallarta<br />
            • 94. Kia Valle Oriente<br />
            • 95. Kia Vallejo<br />
            • 96. Kia Vía Alta<br />
            • 97. Kia Victoria<br />
            • 98. Kia Villas<br />
            • 99. Kia Visión<br /><br />
            *La presente lista puede variar sin previo aviso<br /><br />
            3. USUARIOS AUTORIZADOS Y CUENTA DE USUARIO AUTORIZADO<br /><br />
            - Generalidades<br /><br />
            • Solo habrá un registro de Usuario Autorizado por Distribuidor.<br />
            • Se pueden otorgar permisos a un Usuario para acceso a editar múltiples reportes de otros Distribuidores
            además del propio asignado, en el ingreso a la plataforma.<br /><br />
            • Solamente las siguientes posiciones podrán ser los Usuarios Autorizados:<br /><br />
            o Gerente de Mercadotecnia del Distribuidor, o<br /><br />
            o Coordinador de Mercadotecnia del Distribuidor.<br /><br />
            • De ser solicitados los accesos a los gerentes y coordinadores de marketing de los Distribuidores autorizados
            Kia y/o administradores del portal de KMX; los Usuarios Autorizados podrán compartirlos con previa validación
            del área de Marketing Regional a:<br /><br />
            o Director de Distribuidor.<br /><br />
            o Gerente General de Distribuidor.<br /><br />
            o Gerente de Ventas de Distribuidor.<br /><br />
            • El uso de la Cuenta de Usuario Autorizado será responsabilidad del Usuario Autorizado y del Distribuidor. La
            Cuenta de Usuario Autorizado es para uso personal y la responsabilidad no podrá ser cedida ni transferida a
            otra persona.<br /><br />
            • Las cuentas de cualquier Usuario Autorizado de la plataforma Kia Marketing Portal, serán creadas por los
            Administradores del Sistema Kia Marketing Portal , para abrir una Cuenta de Usuario Autorizado, el Usuario
            Autorizado por el Distribuidor o personal de KMX, proporcionaran los siguientes datos, en el caso de Usuario
            Distribuidor autorizado Kia mediante su Coordinador Regional de Marketing de KMX y en caso de personal de KMX
            será proporcionado directamente al Administrador de Kia Marketing Portal y se requerirá la siguiente
            información.<br /><br />
            o Usuarios Autorizados por el Distribuidor<br /><br />
            a. Nombre del Distribuidor<br /><br />
            b. Nombre Completo del Usuario Autorizado<br /><br />
            c. Correo Electrónico institucional.<br /><br />
            d. El Usuario al aceptar esta Política recibirá notificaciones por medio de correo electrónico al correo
            asignado como Usuario.<br /><br />
            e. El Usuario podrá activar las notificaciones vía WhatsApp directo desde su perfil. Y agregar el número de
            celular para recibir notificaciones vía WhatsApp sobre noticias y actualizaciones en el Kia Marketing
            Portal.<br /><br />
            • La Cuenta de Usuario Autorizado se protegerá mediante una Contraseña. La Contraseña, deberá seguir los
            Criterios para la Construcción de Contraseñas Seguras descrito más abajo.<br /><br />
            • La Cuenta de Usuario Autorizado y su Contraseña son sensibles a mayúsculas y minúsculas, es decir, que estas
            deben ser tecleadas como están.<br /><br />
            • El Usuario Autorizado, no podrá compartir la Cuenta de Usuario Autorizado ni Contraseña con otras personas,
            quienes se mencionan mas no se limitan a: compañeros de trabajo (excepto los autorizados), agencias de
            publicidad y/o cualquier tipo de proveedores internos o externos al Distribuidor, amigos, familiares,
            etc.<br /><br />
            • Si otra persona demanda hacer uso de la Cuenta de Usuario Autorizado hacer referencia a estas políticas. De
            ser necesaria la divulgación de la Cuenta de Usuario Autorizado y su Contraseña asociada, deberá solicitarlo
            por escrito y dirigido al Administrador del Sistema Kia Marketing Portal, con previa autorización del
            Distribuidor.<br /><br />
            • Si se detecta o sospecha que las actividades de una Cuenta de Usuario Autorizado pueden comprometer la
            integridad y seguridad de la información, el acceso a dicha cuenta será suspendido temporalmente y será
            reactivada sólo después de haber tomado las medidas necesarias a consideración del Administrador del Sistema
            Kia Marketing Portal.<br /><br />
            4. TIPOS DE CUENTAS DE USUARIOS AUTORIZADOS<br /><br />
            • 1. Cuenta de Usuario Autorizado (Distribuidor / 1S): todas aquellas cuentas que sean utilizadas por los
            Usuarios en Distribuidores, para acceder a los diferentes sistemas de información. Estas cuentas permiten el
            acceso para consulta, modificación, actualización y se encuentran reguladas por los roles de Usuario del
            Sistema.<br /><br />
            • 2. Cuenta de Administrador de Kia Marketing Portal: corresponde a la cuenta de Usuario que permite al
            administrador del Sistema realizar tareas específicas de Usuario, como, por ejemplo:
            agregar/modificar/eliminar cuentas de Usuario del sistema, agregar/modificar/eliminar información del
            sistema.<br /><br />
            5. CONTRASEÑAS<br /><br />
            • Todas las contraseñas para acceso al Kia Marketing Portal con carácter administrativo deberán ser cambiadas
            al menos cada 6 meses.<br /><br />
            • Todas las contraseñas para acceso al Kia Marketing Portal de nivel Usuario deberán ser cambiadas al menos
            cada 12 (doce) meses.<br /><br />
            • Todas las Contraseñas deberán ser tratadas con carácter confidencial.<br /><br />
            • Una Contraseña debe contener al menos ocho caracteres con al menos un número.<br /><br />
            • Si alguna vez se restablece la Contraseña de un Usuario Autorizado, el Usuario tiene la obligación de
            cambiar la contraseña inmediatamente después del primer inicio de sesión.<br /><br />
            • Si no se accesó a la Cuenta del Usuario Autorizado durante 60 (sesenta) días, la cuenta quedara
            suspendida.<br /><br />
            Política de Contraseñas:<br /><br />
            • - Ninguna Contraseña puede contener el nombre de Usuario Autorizado.<br /><br />
            • - Los dos tipos de Usuarios tienen longitudes mínimas diferentes para las contraseñas:<br /><br />
            • o Administradores (KMX): Al menos 10 caracteres<br /><br />
            • o Usuarios (Distribuidores, AMDK, KMX): Al menos 8 caracteres<br /><br />
            • o Las contraseñas de administrador deben contener al menos dos números y al menos una letra
            mayúscula.<br /><br />
            • - Por seguridad de datos las contraseñas de ninguna manera podrán ser transmitidas mediante servicios de
            mensajería electrónica instantánea ni vía telefónica.<br /><br />
            • - Si es necesario el uso de mensajes de correo electrónico para la divulgación de Contraseñas, estas deberán
            de mantenerse en control y de manera privada<br /><br />
            • - Por seguridad de datos no mencionar y en la medida de lo posible, teclear contraseñas en frente de
            otros.<br /><br />
            • - Por seguridad de datos no revelar contraseñas en cuestionarios, reportes o formas.<br />
            • - Por seguridad de datos no utilizar la misma contraseña para acceso a los sistemas operativos y/o a las
            bases de datos u otras aplicaciones.<br /><br />
            • - Por seguridad de datos no activar o hacer uso de la utilidad de “¿Recordar Contraseña?” o “¿Recordar
            Password?” de las aplicaciones.<br />
            • - No se almacenarán las Contraseñas de manera demostrativa más no limitativa en libretas, agendas, postit,
            hojas sueltas, etc. Si se requiere el respaldo de las Contraseñas en medio impreso, el documento generado
            deberá ser único y bajo resguardo.<br /><br />
            • - No se almacenarán las Contraseñas sin encriptación, en sistemas electrónicos personales (de manera
            demostrativa más no limitativa en asistentes electrónicos personales, memorias USB, teléfonos celulares,
            agendas electrónicas, etc).<br /><br />
            • - En caso de baja o cambio de personal será responsabilidad del Distribuidor informar del cambio y hacer
            cambio inmediato de la contraseña.<br /><br />
            6. INFORMACION CONFIDENCIAL<br /><br />
            Toda la almacenada en el Kia Marketing Portal, que de manera ilustrativa más no limitativa pueda ser
            descargable o no, almacenada a través de cualquier medio óptico, electrónico, impreso, etc., así como
            múltiples tipos de contenidos y formatos según sus funcionalidades almacenados en la base de datos para
            compartir información entre Distribuidores y área de Marketing Regional, cuya dirección es:
            https://kiamarketingportal.com<br /><br />
            7. DATOS PERSONALES:<br /><br />
            KMX pone a disposición de los Usuarios del Kia Marketing Portal en la Sección de Acceso de Kia marketing
            portal en el link con título de Política de Uso y Aviso de Privacidad podrán encontrar el Aviso de Privacidad
            de Kia México, S.A. de C.V., como responsable del tratamiento de los datos personales que nos proporcione, los
            cuales serán protegidos conforme a la Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares (en adelante la “Ley”) y demás normatividad que resulte aplicable.<br /><br />
            8. PROPIEDAD INDUSTRIAL<br /><br />
            • o El acceso y uso del Kia Marketing Portal no implica para el Usuario Autorizado la adquisición de algún
            derecho, licencia, franquicia o interés sobre los derechos de propiedad industrial (marcas, signos
            distintivos, invenciones, derechos de autor u otros derechos similares) que sean propiedad de KMX. o de sus
            empresas filiales, o respecto de los cuales KMX cuente con una autorización de uso.<br /><br />
            • o El Usuario Autorizado estará obligado a evitar cualquier tipo de conducta que pueda incitar, fomentar o
            provocar la lesión y/o afectación de los derechos de propiedad industrial a que se refiere este apartado. De
            igual forma, el Usuario Autorizado tendrá expresamente prohibido el uso de cualquiera de los derechos de
            propiedad industrial propiedad de KMX de C.V., o de sus empresas filiales, incluyéndose en esta prohibición
            mas no limitando a el uso de algún signo distintivo o variante de éste como parte de su denominación social,
            nombre comercial o de cualquier otra identificación bajo la cual realice sus negocios o en relación con
            cualquier tipo de material publicitario o de promoción a su favor.<br /><br />
            • o En el supuesto de que el Usuario Autorizado tuviera conocimiento de cualquier lesión o violación a los
            derechos de propiedad industrial a que se refiere esta cláusula informará de inmediato a KMX y, de
            solicitárselo KMX, deberá colaborar con éste en cualquier gestión, procedimiento o acción legal que sea
            necesaria para la protección y defensa de los mismos.<br /><br />
            • o El Usuario Autorizado en este acto se hace responsable de cualquier daño o perjuicio que resulte de la
            falta de cuidado sobre los derechos de propiedad industrial a que se refiere esta apartado o que se den como
            consecuencia de la falta de aviso oportuno sobre cualquier posible lesión o violación.<br /><br />
            9. DERECHOS DE AUTOR<br /><br />
            Toda la información contenida en el Kia Marketing Portal está protegida por la Ley Federal del Derecho de
            Autor, por lo que el Usuario Autorizado tiene prohibida su reproducción o divulgación para otros fines
            diferentes a marketing, publicidad en cualquiera de sus especialidades, reportes financieros, reportes
            contables, actividades relativos y aplicables a KMX.<br /><br />
            ============================<br />
            AVISO DE PRIVACIDAD INTEGRAL<br /><br />
            DISTRIBUIDORES AUTORIZADOS Kia<br /><br />
            En cumplimiento de las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares (en adelante la “Ley), su Reglamento y el resto de las disposiciones normativas aplicables al
            tratamiento de datos personales, ponemos a su disposición el presente Aviso de Privacidad para informarle
            sobre los términos bajo los cuales trataremos los datos personales que proporcione a Kia, identificando a la
            entidad responsable de su información, las finalidades o acciones que motivan la obtención, uso y custodia de
            sus datos personales; los terceros a quienes se transferirán o se podrán transferir sus datos personales; los
            mecanismos para que pueda ejercer sus derechos, entre otra información que le permitirá tomar decisiones
            informadas sobre el uso de sus datos.<br /><br />
            ¿Quién es el responsable de sus datos personales?<br /><br />
            Kia México, S.A. de C.V. (en adelante e indistintamente el “Responsable” o “Kia”) es el responsable de sus
            datos personales. Nuestra dirección para oír y recibir notificaciones es: Avenida Paseo de la Reforma 250,
            piso 16, Torre B, Colonia Juárez, Alcaldía Cuauhtémoc, Código Postal 06600, Ciudad de México.<br /><br />
            ¿Qué datos usamos y podemos solicitar?<br /><br />
            Para cumplir con las finalidades descritas en este aviso, Kia recaba o puede recabar las siguientes categorías
            de datos personales, relacionados con representantes legales y/o personas de contacto de personas
            morales:<br /><br />
            • 1. Datos identificativos y de contacto;<br />
            • 2. Datos de características personales;<br />
            • 3. Datos académicos y profesionales; y<br />
            • 4. Datos y detalles de ocupación laboral.<br />
            • 5. Datos económicos y financieros.<br />
            • 6. Datos de historial laboral<br />
            • 7. Datos de carácter comercial<br />
            • 8. Datos de transacciones<br /><br />
            ¿Solicitamos datos sensibles?<br /><br />
            Para el cumplimiento de las finalidades descritas en el presente Aviso de Privacidad Kia no solicita datos
            personales sensibles (como datos de salud). Como Distribuidor Autorizado Kia usted debe abstenerse de
            proporcionar ese tipo de información a Kia.<br /><br />
            Datos personales de terceros<br /><br />
            Los Distribuidores Autorizados Kia, a través de representantes legales, serán responsables de informar a
            cualquier tercero (por ejemplo, personas de contacto o empleados) cuyos datos hayan sido proporcionados a Kia
            sobre el tratamiento de sus datos personales y el contenido de este Aviso de Privacidad. Los Distribuidores
            Autorizados Kia que comuniquen datos personales de terceros al Kia manifiestan con su entrega que cuentan con
            el consentimiento previo de sus titulares para proporcionar su información y que los mismos son correctos y
            completos.<br /><br />
            Finalidades del tratamiento de datos personales<br /><br />
            Kia podrá tratar sus datos personales para dos tipos de finalidades: primarias y secundarias.<br /><br />
            Finalidades Primarias:<br />
            • a) Gestión, control y administración de los procesos contenidos y derivados de la relación contractual entre
            Kia y los Distribuidores Autorizados Kia.<br /><br />
            • b) Gestión y administración de documentación de los Distribuidores Autorizados KIA, bajo resguardo de
            Kia.<br /><br />
            • c) Cumplimiento de obligaciones como Distribuidor Autorizado Kia.<br /><br />
            • d) Gestión, control y administración de altas, bajas, modificaciones y actualizaciones de información de
            Distribuidores Autorizados Kia en los sistemas y/o plataformas de Kia; así como atención, seguimiento y
            respuesta a solicitud de soporte sobre dichos sistemas y/o plataformas.<br /><br />
            • e) Creación de cuentas de Usuarios que le permitirán acceder a los sistemas y/o plataformas autorizadas por
            Kia, y asignación de claves y contraseñas.<br /><br />
            • f) Cumplimiento de obligaciones de confidencialidad, honorabilidad y transparencia; así como de
            disposiciones legales, nacionales o internacionales, sobre prevención de actividades ilícitas y otra normativa
            sectorial, mediante la implementación y cumplimiento de procedimientos administrativos y técnicos establecidos
            para tales efectos por Kia.<br /><br />
            • g) Gestión de comunicaciones entre los Distribuidores Autorizados Kia y Kia.<br /><br />
            • h) Análisis de datos mediante el uso de tecnologías de analítica con el objeto de mejorar la gestión
            automatizada de los Distribuidores Autorizados Kia.<br /><br />
            • i) Estadística y registro histórico de representantes legales y personas de contacto de los Distribuidores
            Autorizados Kia.<br /><br />
            • j) Registros de desempeño y evaluaciones de ventas mediante las plataformas de Kia.<br /><br />
            • k) Registros de desempeño y evaluaciones de satisfacción del cliente en servicios de postventa.<br /><br />
            Finalidades Secundarias: No existen.<br /><br />
            ¿Con quién podemos compartir sus datos?<br /><br />
            Kia podrá compartir sus datos personales y llevar a cabo transferencias de datos personales en los términos de
            la Ley y el presente Aviso de Privacidad, tanto dentro como fuera de México, en los siguientes casos y para
            las siguientes finalidades:<br /><br />
            • 1. Hacia nuestra sociedad matriz y/o sociedades controladoras, subsidiarias y/o afiliadas, y/o cualquier
            sociedad del mismo grupo corporativo de Kia que operen bajo los mismos procesos y políticas internas, con
            finalidades de gestión, administración y resguardo centralizado de la información; implementación y
            cumplimiento de procedimientos administrativos y técnicos establecidos para el cumplimiento de obligaciones de
            confidencialidad, honorabilidad y transparencia; así como fines estadísticos y de registro histórico de
            Distribuidores Autorizados Kia<br /><br />
            • 2. Organismos públicos; administraciones públicas federales, estatales y/o municipales; comisiones;
            institutos, entidades reguladoras; autoridades judiciales o administrativas; entidades gubernamentales
            nacionales y/o extranjeras, para el cumplimiento de obligaciones informativas, de transparencia y de
            prevención de actividades ilícitas; así como para el cumplimiento de obligaciones contempladas en la
            legislación aplicable, tratados internacionales y/o en cumplimiento de requerimientos efectuados por aquéllas,
            incluyendo el cumplimiento de obligaciones legales de información, de requerimientos de información y/o de
            requerimiento judiciales o administrativos emitidos por autoridades competentes debidamente fundados y
            motivados.<br /><br />
            • 3. Asesores profesionales y/o prestadores de servicios, con la finalidad de que éstos apoyen al Responsable
            en la gestión de la relación con los distribuidores autorizados Kia.<br /><br />
            La Ley establece que las transferencias de datos antes indicadas no requieren de su consentimiento para poder
            ser efectuadas. Cualquier transferencia de sus datos personales que sí requiera de su consentimiento será
            informada previamente, a través de la comunicación y actualización de este Aviso de Privacidad y de forma
            previa a la realización de esa comunicación de datos.<br /><br />
            Derechos ARCO<br /><br />
            La Ley regula los Derechos ARCO de los titulares de datos personales. Estos derechos comprenden:<br />
            • Acceso: derecho de conocer qué datos personales tratamos sobre Usted; así como información relativa a las
            condiciones y generalidades del tratamiento.<br /><br />
            • Rectificación: derecho de solicitar en todo momento, la rectificación de sus datos que resulten inexactos
            o incompletos.<br /><br />
            • Cancelación: derecho a que cese el tratamiento de sus datos personales, a partir de un bloqueo y su
            posterior supresión.<br /><br />
            • Oposición: derecho a oponerse, por causa legítima, al tratamiento de sus datos personales.<br /><br />
            Para ejercer cualquiera de los Derechos ARCO, deberá presentar una solicitud dirigida a nuestro Departamento
            de Datos Personales, a través de cualquiera de los siguientes canales:<br /><br />
            • a) Enviando una solicitud al correo electrónico datospersonales@infoKia.com.mx, o<br /><br />
            • b) Enviando una solicitud debidamente firmada a la dirección, Avenida Paseo de la Reforma 250, piso 16,
            Torre B, Colonia Juárez, Alcaldía Cuauhtémoc, Código Postal 06600, Ciudad de México.<br /><br />
            Para el ejercicio de cualquiera de los derechos ARCO, ponemos a su disposición el siguiente Formato
            Sugerido.<br /><br />
            La solicitud deberá contener o estar acompañada de:<br /><br />
            • a) Su nombre y domicilio completos, u otro medio para comunicar la respuesta a su solicitud;<br /><br />
            • b) Copia de un documento que acredite su identidad y en su caso, la representación legal si alguien ejerce
            el derecho en su nombre. Podrá omitir este requisito si aporta información que lo acredite como cliente de Kia
            y lo identifique de forma indubitable;<br /><br />
            • c) Una descripción clara y precisa del Derecho ARCO que desea ejercer y de los datos personales relacionados
            con su solicitud; y<br /><br />
            d) En su caso, cualquier otra información o documento que nos ayude a localizar sus datos
            personales.<br /><br />
            Kia responderá a su solicitud dentro de los 20 (veinte) días hábiles siguientes a la fecha en que sea enviada
            y recibida. Si la solicitud resulta procedente, Kia la hará efectiva dentro de los 15 (quince) días hábiles
            siguientes a la fecha en que comunique la respuesta. En caso de que de la información y/o documentación
            proporcionados en su solicitud resulten incompletos, erróneos y/o insuficientes, o bien, no se acompañen los
            documentos necesarios para acreditar su identidad o la representación legal correspondiente, Kia solicitará la
            corrección y subsanación de las deficiencias para poder dar trámite a su solicitud. Usted contará con 10
            (diez) días hábiles para atender el requerimiento y corrección de la solicitud; en caso contrario ésta se
            tendrá por no presentada.<br /><br />
            El uso de medios electrónicos para el ejercicio de los Derechos ARCO autoriza a Kia para dar respuesta a la
            solicitud correspondiente a través del mismo medio, salvo que Usted indique otro medio de contacto en su
            solicitud, de forma clara y expresa.<br /><br />
            El derecho de cancelación no es absoluto. Por favor tome en cuenta que Kia debe conservar información para
            cumplir con diversas obligaciones legales y que para hacerlo puede compartir sus datos personales con otras
            entidades u organismos. En tales casos, es posible que el derecho de cancelación deba solicitarse ante la
            entidad que recibió sus datos personales.<br /><br />
            ¿Quiere revocar su consentimiento o limitar el uso de sus datos?<br /><br />
            Usted podrá revocar el consentimiento para el tratamiento o transferencia de sus datos personales; esta
            revocación puede tener como efecto que no podamos prestarte algunos servicios. Si existen causas de interés
            público u obligaciones legales relacionados con la seguridad de nuestros clientes propietarios de vehículos
            Kia, es posible que podamos negar la revocación de su consentimiento para determinadas
            finalidades.<br /><br />
            Si usted revoca su consentimiento y dicha revocación es procedente, Kia dejará de tratar sus datos personales
            para las finalidades indicadas en este Aviso de Privacidad que resulten procedentes o expresamente
            solicitadas. Sin perjuicio de lo anterior, la revocación de su consentimiento no podrá tener por objeto
            garantizado que los terceros que han tenido acceso a sus datos personales dejen de tratarlos de inmediato, ni
            en el futuro, porque ellos pueden usar su información para finalidades propias y bajo sus propios avisos de
            privacidad.<br /><br />
            Usted podrá enviar su solicitud de revocación de consentimiento al correo electrónico
            datospersonales@infoKia.com.mx, siguiendo las instrucciones aplicables al ejercicio de los Derechos
            ARCO.<br /><br />
            También puede limitar el uso o divulgación de sus datos personales, dirigiendo la solicitud correspondiente a
            nuestro Departamento de Datos Personales. Los requisitos para acreditar tu identidad, así como el
            procedimiento para atender su solicitud serán los mismos que los señalados para el ejercicio de los derechos
            ARCO.<br /><br />
            Kia cuenta con medios y procedimientos para asegurar la inclusión de algunos de tus datos en listados de
            exclusión propios, cuando solicitas su inclusión en ellos de forma expresa. En tales casos, Kia otorgará a los
            titulares que soliciten su registro la constancia de inscripción correspondiente.<br /><br />
            Cambios a nuestro Aviso de Privacidad.<br /><br />
            Kia podrá modificar, actualizar, extender o de cualquier otra forma cambiar el contenido y alcance de este
            Aviso de Privacidad, en cualquier momento y bajo nuestra completa discreción. En todo momento, podrá solicitar
            la última versión de este Aviso de Privacidad a nuestro Departamento de Datos Personales, a través de la
            dirección electrónica datospersonales@infoKia.com.mx. Cuando resulte legalmente procedente y contemos con su
            correo electrónico, Kia podrá comunicar cambios al presente Aviso de Privacidad a través de ese medio de
            comunicación.<br /><br />
            El Aviso de Privacidad que regule el tratamiento de sus datos personales será el que aparezca publicado en
            nuestra página web e identifique el tipo de relación que usted tiene con Kia. Podrá acceder a la última
            versión de este Aviso de Privacidad a través de www.Kia.com/mx, sección Avisos de Privacidad.<br /><br />
            Si ha tenido acceso a este Aviso de Privacidad por medios electrónicos o si desea comunicar su negativa para
            el tratamiento de tus datos para finalidades secundarias, puede comunicar esta decisión en cualquier momento a
            través de los medios establecidos para el ejercicio de sus derechos ARCO.<br /><br />
            Dudas o aclaraciones<br /><br />
            En caso de tener dudas o aclaraciones sobre el presente Aviso de Privacidad, puede contactar a nuestro
            Departamento de Datos Personales a través del correo electrónico datospersonales@infoKia.com.mx.<br /><br />
            Última actualización:<br /><br />
            26 de julio de 2021</p>
        </b-card-text>
      </div>
    </b-modal>
  </div>
</template>

<script>
document.oncontextmenu = function () {
  return false;
};
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import {
  BCard,
  BCardText,
  BCardTitle,
  BSpinner,
  BButton,
  BForm,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BLink,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  VBTooltip,
  BImgLazy,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email, password } from "@validations";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { kiaLogin, createMarketingLog } from "@core/api/request/login";
import jwtdecode from "jwt-decode";

import vSelect from "vue-select";
import i18n from "@/libs/i18n";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    // BSV
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BLink,
    BImgLazy,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  created() {
    localize("es");
  },
  data() {
    return {
      userEmail: "",
      password: "",
      status: "",
      required,
      email,
      remember: false,
      invalid: false,
      loading: false,
      sideImg: null,
      selected: null,
      tycos: false,
      tycosCheck: false,
      params: false,
      // options: [
      //   { value: 'a', text: 'Contract Manager' },
      //   { value: 'b', text: 'Digital Assets Manager' },
      //   { value: 'c', text: 'Budget Manager' }
      // ]
      options: [
        { value: "digitalAssetsManager", text: "Marketing Portal" },
        { value: "buggetManager", text: "Budget Management" },
      ],
      destinations: {
        contractManager: "/contracts",
        digitalAssetsManager: "/marketing",
        buggetManager: "/budget",
      },
    };
  },
  // kia-white.png
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeOffIcon" : "EyeIcon";
    },
  },
  mounted() {
    const params =  this.$route.params.param;
    this.params = params
    console.log(this.params)
    this.selectPlatform(params)
    this.remenberMe();
    localize("es");
  },
  methods: {
    remenberMe() {
      let remenberData = JSON.parse(localStorage.getItem("remind_me"));
      // console.log("fghdfgh", remenberData);
      if (remenberData != null) {
        this.remember = remenberData.rememberme;
        this.userEmail = remenberData.email;
        this.password = remenberData.password;
        //this.selected = remenberData.system;
      }
    },

    login() {
      console.log('Probando')
      // console.log(this.remember);
      this.$refs.loginForm.validate().then((success) => {
        var loginLocked = false;
        if (localStorage.getItem("today")) {
          var actualMinutes = Date.now();
          var locked = localStorage.getItem("today");
          if (actualMinutes < parseInt(locked)) {
            loginLocked = true;
          } else {
            localStorage.removeItem("today");
          }
        }
        if (loginLocked == false) {
          if (success) {
            // console.log("Starting login");
            this.loading = true;

            //console.log("555555", this.selected);
            kiaLogin({
              email: this.userEmail,
              password: this.password,
            })
              .then((response) => {
                console.log("Response ", response);
                localStorage.setItem("test", JSON.stringify(response.data.data));

                //this.alertToast('success', 'Acceso exitoso', 'CheckIcon')
                this.loading = false;

                //DECODIFICAR
                const userData = jwtdecode(response.data.data);
                // console.log(this.selected);
                // console.log(userData[this.selected.value], "select");
                if (userData.active == true) {
                  if (userData[this.selected.value]) {
                    useJwt.setToken(response.data.data);


                    let remenberData = {
                      rememberme: this.remember,
                      email: this.userEmail,
                      password: this.password,
                      system: this.selected,
                    };

                    localStorage.setItem("userData", JSON.stringify(userData));
                    location.href = this.destinations[this.selected.value];

                    if(this.selected.value == "digitalAssetsManager"){
                      createMarketingLog(this.userEmail)
                    }

                    if (this.remember)
                      localStorage.setItem(
                        "remind_me",
                        JSON.stringify(remenberData)
                      );
                    else localStorage.removeItem("remind_me");
                  } else {
                    this.alertToast(
                      "danger",
                      "No tienes permisos para ingresar a esta plataforma",
                      "XIcon"
                    );
                  }
                } else {
                  this.alertToast(
                    "danger",
                    "Su usuario se encuentra inactivo",
                    "XIcon"
                  );
                }
                // const { userData } = response.data
                // useJwt.setToken(response.data.accessToken)
                // useJwt.setRefreshToken(response.data.refreshToken)
                // localStorage.setItem('userData', JSON.stringify(userData))
                // this.$ability.update(userData.ability)

                // ? This is just for demo purpose as well.
                // ? Because we are showing eCommerce app's cart items count in navbar
                // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                //   .then(() => {
                //     this.$toast({
                //       component: ToastificationContent,
                //       position: 'top-right',
                //       props: {
                //         title: `Welcome ${userData.fullName || userData.username}`,
                //         icon: 'CoffeeIcon',
                //         variant: 'success',
                //         text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                //       },
                //     })
                //   })
              })
              .catch((error) => {

                this.$store.commit("app/LOGIN_ATTEMPT");
                this.alertToast(
                  "danger",
                  error.response.data.message.charAt(0).toUpperCase() +
                  error.response.data.message.slice(1).toLowerCase(),
                  "XIcon"
                );
                this.loading = false;
                // this.$refs.loginForm.setErrors(error.response.data.error)
              });
          }
        } else {
          this.alertToast(
            "danger",
            "El inicio de sesión se encuentra bloqueado, intente más tarde",
            "XIcon"
          );
          this.loading = false;
        }
      });
    },

    recaptcha() {
      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LeJ5MQmAAAAAHY40ZyJb8PBOK9GiZw_RboBAKBc", {
            action: "submit",
          })
          .then((token) => {

            this.login();
          })
          .catch((error) => console.log(error));
      });
    },

    alertToast(type = null, title = null, icon = null) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon: icon,
            variant: type,
            text: null,
          },
        },
        {
          position: "top-right",
        }
      );
    },

    showTycos() {
      this.tycos = true
    },

    selectPlatform(param) {
      console.log(param)
      if(param == 'marketing') {
        this.selected = { value: "digitalAssetsManager", text: "Marketing Portal" }
        this.params = true
      } else if (param == 'contract') {
        this.selected = { value: "contractManager", text: "Contract Management" }
        this.params = true
      } else if (param == 'bugget') {
        this.selected = { value: "buggetManager", text: "Budget Manager" }
        this.params = true
      } else {
        this.selected = null
        this.params = false
        this.$router.push({
          name:'login'})
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: url("../../../assets/images/pages/login/background.png") center center;
  background-size: cover;
}</style>

function newFunction() { jwt-decode }
